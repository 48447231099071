<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>Manage permissions</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <v-btn
          small
          @click="addUserGroup = true">
            Add User Group
        </v-btn>
        &nbsp;
        <v-btn
          small
          @click="viewGroups = true">
            Edit User Groups
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-select
          required
          label="Select user group"
          :items="userGroups"
          item-text="name"
          item-value="id"
          v-model="userGroupId">
        </v-select>
      </v-col>
    </v-row>
    <v-row v-for="(permission, index) in permissions" :key="index">
      <v-col>
        {{ permission.name }}:
        {{ permission.description}}
      </v-col>
      <v-col>
        <input type="checkbox"
          :value="permission.id"
          name="permission"
          v-model="permissionList">
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="savePermissions">Save</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="addUserGroup"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          Add new user group
          <v-text-field
            v-model="newUserGroup"
            label="Name"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="saveUserGroup"
          >
            Save
          </v-btn>
          &nbsp;
          <v-btn
            color="orange darken-2"
            text
            @click="addUserGroup = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="viewGroups"
      width="600"
    >
      <v-card>
        <v-card-text class="pt-4">
          <div v-for="(group, index) in userGroups" :key="`ug_${index}`">
            <v-row>
              <v-col class="col-9">
                <v-text-field :label="group.name" v-model="group.name"></v-text-field>
              </v-col>
              <v-col class="col-3 pt-9">
                <v-btn @click="updateGroupName(index)" x-small >Save</v-btn>
              </v-col>
            </v-row>            
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="viewGroups = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'Permissions',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      message: '',
      showMessage: false,
      userGroups: [],
      userGroupId: 0,
      permissions: [],
      permissionList: [],
      addUserGroup: false,
      newUserGroup: '',
      viewGroups: false,
    };
  },
  watch: {
    userGroupId(value) {
      const userGroup = this.userGroups.filter(group => group.id === value)[0];
      this.permissionList = userGroup.permissions;
    },
  },
  methods: {
    saveUserGroup() {
      const postData = {};
      postData.userGroup = this.newUserGroup;
      axios.post(`/userGroups/create.json?token=${this.token}`, postData)
				.then((response) => {
					this.message = response.data.message;
          this.showMessage = true;
          this.newUserGroup = '';
          this.addUserGroup = false;
          this.userGroups = response.data.usergroups;
				})
				.catch((error) => {
					console.log(error);
				});
    },
    savePermissions() {
      const postData = {};
      postData.userGroup = this.userGroupId;
      postData.permissionList = this.permissionList;
      axios.post(`/permissionsUserGroups/savePermissions.json?token=${this.token}`, postData)
				.then((response) => {
					this.message = response.data.message;
          this.showMessage = true;
				})
				.catch((error) => {
					console.log(error);
				});
    },
    getUserGroups() {
      axios.get(`/userGroups/getAll.json?token=${this.token}`)
				.then((response) => {
					this.userGroups = response.data.usergroups;
				})
				.catch((error) => {
					console.log(error);
				});
    },
    getPermissions() {
      axios.get(`/permissions/getAll.json?token=${this.token}`)
				.then((response) => {
					this.permissions = response.data.permissions;
				})
				.catch((error) => {
					console.log(error);
				});
    },
    updateGroupName(index) {
      const group = this.userGroups[index];
      axios.post(`/userGroups/updateName.json?token=${this.token}`, group)
				.then((response) => {
					this.message = response.data.message;
          this.showMessage = true;
				})
				.catch((error) => {
					console.log(error);
				});
    },
  },
  mounted() {
    this.getUserGroups();
    this.getPermissions();
  },
};
</script>
